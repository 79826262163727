@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
body{font-family:"Roboto",sans-serif;font-weight:normal;font-style:normal}
.page-en-construction {
  font-size: 2em;
}
.page-raccourcis {
  margin-top: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.page-raccourcis-lien {
  font-size: 1em;
  border-radius: 4px;
  text-decoration: none;
  background-color: red;
  transition: 1s all ease;
  padding: 0.5em 1em 0.5em 1em;
}
.page-raccourcis-lien:hover {
  transition: 1s all ease;
  font-size: 1em;
  color: white;
  background-color: blue;
  padding: 0.5em 1em 0.5em 1em;
}
.page-raccourcis-adresse {
  margin-top: 12em;
  font-size: 12px;
  color: #8d8d8d;
}
#page-chargement, #page-securite {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100vh !important;
}
.hideitem {
  display: none;
}
/*.page-youtube-slider {
  width: 80%;
  margin:auto;
  padding:20px;
  background-color:#f4f4f4;
  border-radius:10px;
}

.page-video-container {
  position:relative;
  padding-bottom:56.25%;
  height: 0;
}

.page-video-container iframe {
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}*/
.page-youtube-iframe {
  width: 100%;
  height: 550px;
}
#page-youtube-div {
  margin: 0 2em !important;
}
.page-boutik-div {
  width: 100%;
  height: 320px;
}
#page-newsletter {
  background-image: url('../public/images/cover-newsletter.jpg');
  background-size: cover;
  background-position: center;
  height: 350px;
  background-attachment: fixed !important;
}

/* Modal style */
.modal {
  display: none;
  position:fixed;
  z-index:1;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  overflow:auto;
  background-color:rgba(0,0,0,0.5);
  transition: 1s all;
}

.modal-show {
  display:flex;
  position: fixed;
  width: 100vw;
  height: 100% !important;
  z-index: 9999;
  background: #1976d2ed;
  overflow-y: scroll;
  transition: 1s all;
}

.modal-content {
  background-color:#fefefe;
  position: static;
  margin:15% auto;
  width: 60%;
  height: 320px;
  border-radius: 5px;
}
.modal-boutik-content, .modal-achats-content {
  background-color: #fefefe;
  position: static;
  margin: auto;
  width: 68%;
  height: fit-content;
  border-radius: 5px;
}
.close-button {
  color:#aaa;
  float:right;
  font-size: 28px;
  font-weight: bold;
}
.close-button:hover, .close-button:focus {
  color:black;
  text-decoration:none;
  cursor:pointer;
}
#product-small {
  width: 70% !important;
}
#product-row {
  height: fit-content !important;
}
#recherche-resultat {
  display: block;
  position: relative;
}
#video-titre-truncate {
  height: 100px; 
  word-break: normal;
  overflow: hidden;
  line-height: 1;
}
#transition-effect {
  -webkit-transition: all 0.55s ease-in-out;
  -o-transition: all 0.55s ease-in-out;
  transition: all 0.55s ease-in-out;
}
.swiper-wrapper {
  height: max-content !important;
  width: max-content;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "" !important;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "" !important;
}
#liste-produits-panier {
  height: 440px;
  overflow-y: scroll;
}
#formulaire-commande {
  height: 440px;
  overflow-y: scroll;
}
.naviguerAuTop {
  position: fixed;
  bottom: 3em;
  z-index: 9999;
  left: 85%;
}
.btn-admin-menu {
  background-color: white;
}
#editor-quill {
  height: 380px;
  background-color: white;
  margin-bottom: 2em;
}



