@media screen and (max-width: 1921px) {
	#page-actu-slider3, #page-actu-slider2, #page-actu-slider1 {
		display: none !important;
	}
	#page-actu-slider4 {
		display: block !important;	
	}
	.page-actu-description {
		display: block;
	}
	.page-actu-titre2 {
		line-height:normal;
	}
	#page-boutique-description {
		font-size: 1.2em;
		line-height: normal;
		letter-spacing: 0;
	}
	.w-newsletter-input {
		width: 100%;
	}
	.w-panieritem-image {
		width: 40%;
	}
	.w-panieritem-nom {
		width: 60%;
	}
	.w-panier-achats {
		width: 70%;
	}
	.w-panier-informations {
		width: 30%;
	}
	.w-panieritem-titre {
		width:70% !important;
	}
	.w-panieritem-infos {
		width:30% !important;
	}
	.w-admin-menu {
		flex-wrap: wrap;
	}
	/* options administrateur */
	.w-adminproduits-nom {
		font-size: 2em;
		line-height: normal;
		width: 27%;
		padding: 0 0.5em;
	}
	.w-admincmd-nom {
		font-size: 1em;
		line-height: normal;
		width: 27%;
		padding: 0 0.5em;
	}
	.w-adminproduits-image {
		width: 12%;
		padding: 5px;
	}
	.w-adminproduits-description {
		width: 23%;
		height: 150px;
		overflow: scroll;
		padding: 0 1em;
		border-right: 1px solid gray;
		border-left: 1px solid gray;
	}
	.w-adminproduits-prix {
		font-size: 1.5em;
		padding: 0 1em;
		width: 9%;
		border-right: 1px solid gray;
	}
	.w-adminproduits-zoneqte > span {
		width: 50%;
	}
	.w-adminproduits-zoneqte {
		width: 17%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		border-right: 1px solid gray;
	}
	.w-adminproducts-options {
		width: 11%;
		padding: 0 1em;
	}
	.w-adminproduits-form1 {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	#txt-adminproduits-serial {
		width: 25%;
	}
	#txt-adminproduits-nom {
		width: 50%;
	}
	#txt-adminproduits-prix {
		width: 10%;
	}
	#txt-adminproduits-details {
		width: 100%;
	}
	.w-adminproducts-form2 {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-around;
	}
	#txt-adminproduits-stock {
		width: 10%;
	}
	#txt-adminproduits-folder {
		width: 15%;
		font-size: 14px;
		overflow:auto;
	}
	#txt-adminproduits-date {
		width: 30%;
	}
	.w-adminpagination-content {
		margin-top: 1em;
		width: 260px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}
}
@media screen and (max-width: 1367px) {
	#page-actu-slider4, #page-actu-slider2, #page-actu-slider1 {
		display: none !important;
	}
	#page-actu-slider3 {
		display: block !important;	
	}
	.page-actu-description {
		display: block;
	}
	.page-actu-titre2 {
		line-height:normal;
	}
	#page-boutique-description {
		font-size: 0.9em;
		line-height: normal;
		letter-spacing: 0;
	}
	.w-panieritem-nom > p {
		font-size: 1.1em;
		line-height: normal;
	}
	.w-panier-achats {
		width: 60%;
	}
	.w-panier-informations {
		width: 40%;
	}
	.w-panieritem-titre {
		width:65% !important;
	}
	.w-panieritem-infos {
		width:35% !important;
	}
}
@media screen and (max-width: 1024px) {
	#page-boutique-div2 {
		margin-top: 2.5em;
	}
	.w-panier-achats {
		width: 100%;
	}
	.w-panier-informations {
		width: 100%;
	}
	.w-panieritem {
		flex-direction: column;
	}
	.w-panieritem-image {
		width: 30%;
	}
	.w-panieritem-nom {
		width: 70%;
	}
	.w-panieritem-nom > p {
		font-size: 2.7em;
		line-height: 1;
	}
	.w-panieritem-nom > span {
		font-size: 3em;
	}
	.w-panieritem-titre {
		width:100% !important;
	}
	.w-panieritem-infos {
		width:100% !important;
		margin-top: 1em;
	}
	.w-panieritem-infos > div {
		width: 100%;
		font-size: 2em;
	}
	.w-panieritem-infos > div > span {
		width: 40%;
		font-size: 1em;
	}
	.w-panieritem-infos > h6 {
		margin-top: 8px;
		font-size: 1.5em;
	}
}
@media screen and (max-width: 961px) {
	#page-actu-slider4, #page-actu-slider2, #page-actu-slider1 {
		display: none !important;
	}
	#page-actu-slider3 {
		display: block !important;	
	}
	.page-actu-description {
		display: none;
	}
	.page-actu-titre2 {
		line-height:1em;
	}
	#page-boutique-description {
		font-size: 1.4em;
		line-height: normal;
		letter-spacing: 0;
	}
	.w-panieritem-image {
		width: 40%;
	}
	.w-panieritem-nom {
		width: 60%;
	}
	.w-panieritem-nom > p {
		font-size: 1.6em;
		line-height: 1.4;
	}
	.w-panieritem-nom > span {
		font-size: 2.2em;
	}
}
@media screen and (max-width: 769px) {
	#page-actu-slider4, #page-actu-slider3, #page-actu-slider1 {
		display: none !important;
	}
	#page-actu-slider2 {
		display: block !important;	
	}
	.page-actu-description {
		display: none;
	}
	.page-actu-titre2 {
		line-height:1em;
		margin-left: -0.7em;
		font-size: 1em;
	}
	#page-top-left {
		display: none;
	}
	#page-top-right {
		display: none;
	}
	#page-top-center {
		width: 100%;
	}
	#page-produit-contain {
		width: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		height: 540px;
	}
	.w-newsletter-item {
		flex-direction: column;
		align-items: center;
		width: 90% !important;
	}
	.w-newsletter-item > button {
		width: 99%;
		background-color: #ddddf1;
		padding: 7px 0 5px 0;
	}
	.w-newsletter-input {
		width:100%;
		margin-bottom: 0.5em;
	}
	/* page admin */
	.w-adminproduits-form1 {
		display: flex;
		flex-direction: column;
		margin-bottom: 1.5em;
	}
	#txt-adminproduits-serial {
		width: 100%;
	}
	#txt-adminproduits-nom {
		width: 100%;
	}
	#txt-adminproduits-prix {
		width: 100%;
	}
	.w-adminproducts-form2 {
		display: flex;
		flex-direction: column;
	}
	#txt-adminproduits-stock {
		width: 100%;
	}
	#txt-adminproduits-folder {
		width: 100%;
	}
	#txt-adminproduits-date {
		width: 100%;
	}
}
@media screen and (max-width: 641px) {
	#page-index-produitsvh {
		display: none;
	}	
	.w-panieritem-image {
		width: 100%;
	}
	.w-panieritem-nom {
		margin-top: 1.5em;
		width: 100%;
	}
	.w-panieritem-nom > p {
		font-size: 2em;
		line-height: 1.1;
	}
	.w-panieritem-nom > span {
		font-size: 2.4em;
	}
}
@media screen and (max-width: 501px) {
	#page-actu-slider4, #page-actu-slider3, #page-actu-slider2 {
		display: none !important;
	}
	#page-actu-slider1 {
		display: block !important;	
	}
	.page-actu-description {
		display: block;
	}
	.page-actu-titre2 {
		line-height:1em;
		margin-left: -0.7em;
		font-size: 1.2em;
	}
}
